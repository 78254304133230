export class Google {
  constructor(public state: string, public next: string) {
    this.next = next;
    this.state = state;

    /**
     * {@link https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.initialize}
     */
    window.google.accounts.id.initialize({
      client_id: CIRRUS_AUTH_GOOGLE_CLIENT_ID,
      ux_mode: 'redirect',
      login_uri: `${window.location.origin}/front/api/v1t/oauth/google`,
    });
  }

  renderButton(element: HTMLDivElement, signIn = false) {
    /**
     * {@link https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.renderButton}
     */
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    window.google.accounts.id.renderButton(element, {
      type: 'standard',
      shape: 'rectangular',
      theme: 'filled_blue',
      text: signIn ? 'signin_with' : 'signup_with',
      size: 'large',
      logo_alignment: 'left',
      width: 352,
      state: this.state,
    } as any);
  }
}
