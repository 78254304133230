import React, {PropsWithChildren, useState} from 'react';
import {Box, Link, Menu, MenuItem, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Sx} from 'app/types/common';
import {Button} from 'app/components/sharedReactComponents/Button';
import {THEME, VARIANT} from 'app/constants';
import {router} from 'app/router/main';
import {Icons} from 'app/util/icons';
import {useConnectAction} from 'app/components/MeetingsManager/ConnectManager';
import {isNil} from 'app/util/isNil';
import {PlatformAccount} from 'app/components/MeetingsManager/MeetingsHeader/PlatformAccount/PlatformAccount';

interface Props extends Sx {
  tenants: App.Connect.Auth.TeamsTenant[];
  accounts: App.Connect.Auth.ZoomAccount[];
  canCreate: boolean;
  canEditTenants: boolean;
}

export function MeetingsHeader({sx, tenants, accounts, canCreate, canEditTenants}: Props) {
  const {openTeams, openZoom} = useConnectAction();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();
  const openMenu = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => setAnchorEl(undefined);

  const open = !isNil(anchorEl);

  const renderList = () => {
    if (tenants.length === 0) {
      return null;
    }

    return (
      <div>
        <span>
          <Typography component="span" mb={0.5} fontWeight="bold" color={grey[500]}>
            Connected accounts:
          </Typography>{' '}
          {canEditTenants && <Link href={router.url('connectAccountSettings')}>edit</Link>}
        </span>

        <Stack flexWrap="wrap" mt={1} direction="row" alignItems="center" columnGap={2} rowGap={1}>
          {tenants.map((t) => (
            <PlatformAccount key={t.id} name={t.name} platform="teams" available={t.available} />
          ))}
          {accounts.map((acc) => (
            <PlatformAccount key={acc.id} name={acc.name} platform="zoom" available={acc.ready} />
          ))}
        </Stack>
      </div>
    );
  };

  const handleTeams = () => {
    openTeams();
    closeMenu();
  };

  const handleZoom = () => {
    openZoom();
    closeMenu();
  };

  return (
    <Box sx={sx}>
      <Typography fontWeight="bold" fontSize={24}>
        All meetings
      </Typography>

      <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
        {renderList()}

        <Box sx={{ml: 'auto', '& > button': {width: 196}}}>
          <Button
            dataId="connect_to_meeting"
            theme={THEME.PRIMARY}
            variant={VARIANT.SOLID}
            disabled={!canCreate}
            onClick={openMenu}
          >
            <Stack direction="row" justifyContent="space-between" flex={1}>
              <span>New meeting</span>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Stack>
          </Button>
          <Menu anchorEl={anchorEl} open={open} PaperProps={{sx: {width: 196}}} onClose={closeMenu}>
            <MenuItem data-id="open-teams-btn" onClick={handleTeams}>
              <Source>
                {Icons.microsoftTeams().reactComponent()}
                <span>Microsoft Teams</span>
              </Source>
            </MenuItem>
            <MenuItem data-id="open-zoom-btn" onClick={handleZoom}>
              <Source>
                {Icons.zoom().reactComponent()}
                <span>Zoom</span>
              </Source>
            </MenuItem>
          </Menu>
        </Box>
      </Stack>
    </Box>
  );
}

function Source({children}: PropsWithChildren<{}>) {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      {children}
    </Stack>
  );
}
