import React from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {Stack} from '@mui/material';
import {Callback, ClassName} from 'app/types/common';
import {EmptyFilterMessage} from 'app/components/sharedReactComponents/EmptyFilterMessage/EmptyFilterMessage';
import {Meeting} from 'app/store/models/connect/Meeting/Meeting';
import {MeetingCardHeader} from 'app/components/MeetingsManager/MeetingCardHeader/MeetingCardHeader';
import {CallCard, MeetingCard} from 'app/components/MeetingsManager/cards';
import {useConnectAction} from 'app/components/MeetingsManager/ConnectManager';
import {router} from 'app/router/main';

interface Props extends ClassName {
  items: Array<Meeting | App.Connect.Call>;
  hasFilter: boolean;
  canStart: boolean;
  onClearFilter: Callback;
}

export const MeetingsList = observer(
  ({className, items, hasFilter, canStart, onClearFilter}: Props) => {
    const {enterPasscode, setIdentity, deleteCall} = useConnectAction();

    const handleMeetingClick = (m: Meeting) => {
      if (m.status === 'waiting-for-password') {
        enterPasscode(m.id);
        return;
      }

      if (m.status === 'waiting-for-account') {
        setIdentity({id: m.id, identity: 'guest'});
      }

      if (m.status === 'running') {
        router.go('meetingDetails', {meetingId: m.id});
      }
    };

    const handleDeleteCall = (c: App.Connect.Call) => deleteCall(c);

    const noEntities = items.length === 0;
    const showEmptyFilter = hasFilter && noEntities;

    return (
      <div className={classNames('meetings-list', className)}>
        {showEmptyFilter ? (
          <EmptyFilterMessage
            message="Search applied, but no results found."
            buttonText="Reset search"
            handleClear={onClearFilter}
          />
        ) : (
          <>
            <MeetingCardHeader />
            <Stack gap={1}>
              {items.map((item) => {
                if (item instanceof Meeting) {
                  return (
                    <MeetingCard
                      key={item.id}
                      dataId={item.id}
                      className="meetings-list__meeting"
                      meeting={item}
                      canStart={canStart}
                      onClick={handleMeetingClick}
                    />
                  );
                }

                return (
                  <CallCard
                    key={item.id}
                    call={item}
                    dataId={item.id}
                    canStart={canStart}
                    onDelete={handleDeleteCall}
                  />
                );
              })}
            </Stack>
          </>
        )}
      </div>
    );
  },
);
