import React, {ComponentProps, useCallback, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import {
  ContextMenuItem,
  EllipsedContextMenu,
} from 'app/components/sharedReactComponents/ContextMenu';
import {Icons} from 'app/util/icons';
import {SIZE, THEME} from 'app/constants';
import {UpdateDeviceFirmwarePrompt} from 'app/components/libs/dialogs/UpdateDeviceFirmwarePrompt';
import {AVAILABLE_FOR_PRO_PLAN_TOOLTIP_MESSAGE} from 'app/components/sharedReactComponents/AvailableForProPlanTooltip/constants';
import {Callback, ClassName} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {FirmwareFeature} from 'app/store/models/features/firmware/FirmwareFeature';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {noop} from 'app/util/noop';
import {AbortCallback} from 'app/api/types';
import {MoveToGroupDialog} from 'app/components/dialogs/MoveToGroupDialog/MoveToGroupDialog';
import {Edge} from 'app/domain/edge';
import {Notifications} from 'app/components/Notifications';
import {useMounted} from 'app/hooks/useIsMounted';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {RemoveFromGroupDialog} from 'app/components/dialogs/RemoveFromGroupDialog/RemoveFromGroupDialog';
import {ApplyPresetToDevicesDialog} from 'app/components/dialogs/ApplyPresetToDevicesDialog/ApplyPresetToDevicesDialog';
import {RebootDevicesDialog} from 'app/components/dialogs/RebootDevicesDialog/RebootDevicesDialog';
import {ProjectsSwitcherDialog} from 'app/components/dialogs/ProjectsSwitcherDialog/ProjectsSwitcherDialog';
import {DeleteProjectsDialog} from 'app/components/dialogs/DeleteProjectsDialog/DeleteProjectsDialog';
import {DeleteDevicesDialog} from 'app/components/dialogs/DeleteDevicesDialog/DeleteDevicesDialog';

type ProjectAction = ComponentProps<typeof ProjectsSwitcherDialog>['action'];

interface Props extends ClassName {
  device: AnyDeviceModelType;
  groups: Edge.Group[];
  permitReboot: boolean;
  permitPreset: boolean;
  permitUnify: boolean;
  presets: Edge.TeamPreset[];
  onApplyPreset: (preset: Edge.TeamPreset) => Promise<void>;
  onMoveToGroup: (groupId: string) => Promise<void>;
  onRemoveFromGroup: () => Promise<void>;
  onStartProject?: () => Promise<any>;
  onStopProject?: () => Promise<any>;
  onDelete: () => Promise<any>;

  unpairAction: Callback;
}

export const ContextMenu = React.memo(
  ({
    className,
    device,
    groups,
    permitPreset,
    permitReboot,
    permitUnify,
    presets,
    onApplyPreset,
    onMoveToGroup,
    onRemoveFromGroup,
    onStartProject,
    onStopProject,
    onDelete,

    unpairAction,
  }: Props) => {
    const [presetDialog, setPresetDialog] = useState(false);
    const [rebootDialog, setRebootDialog] = useState(false);
    const [moveToGroupDialog, setMoveToGroupDialog] = useState(false);
    const [removeFromGroupDialog, setRemoveFromGroupDialog] = useState(false);
    const [projectDialog, setProjectDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const mounted = useMounted();

    const groupsAccess = device.capabilities.groups;

    const deviceGroup = device.getGroupId() ?? '';
    const model = device.getModelName();
    const name = device.getName() ?? '';
    const isOnline = device.isOnline();

    const isUnify = ModelService.isUnify(model);
    const isLvs = ModelService.isLivescrypt(model);

    const disableReboot = !device.capabilities.reboot || !permitReboot;

    const applyPresetDisabled = !device.capabilities.presets || device.isOffline() || !permitPreset;

    const [firmware, setFirmware] = useState<FirmwareFeature | undefined>(undefined);
    const [firmwareLoading, setFirmwareLoading] = useState<boolean>(true);

    const updateAvailable = firmware
      ? firmware.updateInProgress || firmware.availableVersion
      : false;

    const disabledFirmwareOption = device.isOffline() || firmwareLoading || !updateAvailable;

    const abortCb = useRef<AbortCallback>(noop);

    const onOpen = useCallback(async () => {
      if (device.isOffline() || isUnify) {
        setFirmwareLoading(false);
        return;
      }

      try {
        setFirmwareLoading(true);
        const response = await DeviceApiService.getFirmware(
          device.getId(),
          (abort) => (abortCb.current = abort),
        );
        if (!response) {
          throw new Error('firmware is null');
        }

        setFirmware(new FirmwareFeature(response));
      } catch {
        setFirmware(undefined);
      } finally {
        setFirmwareLoading(false);
      }
    }, [device, isUnify]);

    const onClose = useCallback(() => {
      if (!isUnify) {
        abortCb.current();
        setFirmware(undefined);
      }
    }, [abortCb, isUnify]);

    const availableGroups = useMemo(() => {
      if (groupsAccess) {
        return groups.filter((g) => g.id !== deviceGroup);
      }

      return [];
    }, [groupsAccess, groups, deviceGroup]);

    const currentGroup =
      groupsAccess && deviceGroup ? groups.find((g) => g.id === deviceGroup) : undefined;

    const handleRemoveFromGroup = async () => {
      await onRemoveFromGroup();

      if (mounted()) {
        setRemoveFromGroupDialog(false);
      }
    };

    const handleReboot = async () => {
      try {
        await device.sendRebootCommand();

        if (mounted()) {
          setRebootDialog(false);
        }
      } catch {
        Notifications.addErrorNotification('Failed to reboot device');
      }
    };

    const handleSwitch = async (action: ProjectAction) => {
      try {
        if (action === 'start') {
          await onStartProject?.();
        } else {
          await onStopProject?.();
        }
        setProjectDialog(false);
      } catch {
        Notifications.addErrorNotification(`Failed to reboot device`);
      }
    };

    const handleApplyPreset = async (preset: Edge.TeamPreset) => {
      await onApplyPreset(preset);

      if (mounted()) {
        setPresetDialog(false);
      }
    };

    const handleDelete = async () => {
      try {
        await onDelete();

        if (mounted()) {
          setDeleteDialog(false);
        }
      } catch {
        Notifications.addErrorNotification('Failed to delete device');
      }
    };

    return (
      <div className={classNames('device-card-context-menu', className)}>
        <ApplyPresetToDevicesDialog
          open={presetDialog}
          unitCount={1}
          model={model}
          presets={presets}
          rebootWarning={!isUnify}
          onApply={handleApplyPreset}
          onClose={() => setPresetDialog(false)}
        />

        {groupsAccess && (
          <>
            <MoveToGroupDialog
              open={moveToGroupDialog}
              devices={[]}
              groups={availableGroups}
              onMove={onMoveToGroup}
              onRemove={deviceGroup ? handleRemoveFromGroup : undefined}
              onClose={() => setMoveToGroupDialog(false)}
            />

            {currentGroup && (
              <RemoveFromGroupDialog
                open={removeFromGroupDialog}
                devices={[device]}
                onRemove={handleRemoveFromGroup}
                onClose={() => setRemoveFromGroupDialog(false)}
              />
            )}
          </>
        )}

        {isUnify ? (
          <>
            <ProjectsSwitcherDialog
              open={projectDialog}
              names={[name]}
              action={isOnline ? 'stop' : 'start'}
              onSwitch={handleSwitch}
              onClose={() => setProjectDialog(false)}
            />

            <DeleteProjectsDialog
              open={deleteDialog}
              names={[name]}
              onDelete={handleDelete}
              onClose={() => setDeleteDialog(false)}
            />
          </>
        ) : (
          <>
            <RebootDevicesDialog
              open={rebootDialog}
              names={[name]}
              onReboot={handleReboot}
              onClose={() => setRebootDialog(false)}
            />

            <DeleteDevicesDialog
              open={deleteDialog}
              names={[name]}
              lvsOnly={isLvs}
              onDelete={handleDelete}
              onClose={() => setDeleteDialog(false)}
            />
          </>
        )}

        <EllipsedContextMenu
          buttonDataId="device_card_context_menu_button"
          size={SIZE.S}
          items={[
            ...(isUnify
              ? createUnifyProjectDeviceOptions({
                  device,
                  permitted: permitUnify,
                  onStart: () => {
                    setProjectDialog(true);
                  },
                  onStop: () => {
                    setProjectDialog(true);
                  },
                })
              : []),
            ...(isUnify
              ? []
              : [
                  {
                    label: 'Update firmware',
                    itemDataId: 'update_firmware_option',
                    icon: Icons.updateFirmware().reactComponent(),
                    disabled: disabledFirmwareOption,
                    loading: firmwareLoading,
                    action: () => {
                      UpdateDeviceFirmwarePrompt.show({
                        devices: [device],
                      });
                    },
                  },
                  {
                    separator: true,
                  },
                ]),
            ...(isUnify
              ? []
              : [
                  {
                    label: 'Move to group',
                    itemDataId: 'move_to_group_option',
                    icon: Icons.groupMove().reactComponent(),
                    visible: groupsAccess,
                    action: () => setMoveToGroupDialog(true),
                  },
                  {
                    label: 'Remove from group',
                    itemDataId: 'remove_from_group_option',
                    icon: Icons.groupRemove().reactComponent(),
                    visible: groupsAccess && Boolean(deviceGroup),
                    action: () => setRemoveFromGroupDialog(true),
                  },
                  {
                    separator: true,
                    visible: groupsAccess,
                  },
                ]),
            {
              label: 'Apply preset',
              itemDataId: 'apply_preset_option',
              icon: Icons.selectPreset().reactComponent(),
              danger: true,
              disabled: applyPresetDisabled,
              title: !device.capabilities.presets
                ? AVAILABLE_FOR_PRO_PLAN_TOOLTIP_MESSAGE
                : undefined,
              action: () => setPresetDialog(true),
            },
            ...(isUnify
              ? []
              : [
                  {
                    label: 'Reboot',
                    itemDataId: 'reboot_option',
                    icon: Icons.retry().reactComponent(),
                    danger: true,
                    disabled: disableReboot,
                    action: () => {
                      setRebootDialog(true);
                    },
                  },
                  {
                    label: 'Unpair',
                    itemDataId: 'unpair_option',
                    icon: Icons.unlink().reactComponent(),
                    danger: true,
                    disabled: device.isUnpaired(),
                    action: () => unpairAction([device]),
                  },
                ]),
            {
              label: 'Delete',
              itemDataId: 'delete_option',
              icon: Icons.trash().reactComponent(),
              danger: true,
              disabled: isUnify ? !permitUnify : false,
              action: () => {
                setDeleteDialog(true);
              },
            },
          ]}
          onOpen={onOpen}
          onClose={onClose}
        />
      </div>
    );
  },
);

interface Args {
  device: AnyDeviceModelType;
  permitted: boolean;
  onStart: () => void;
  onStop: () => void;
}

function createUnifyProjectDeviceOptions({
  device,
  permitted,
  onStart,
  onStop,
}: Args): ContextMenuItem[] {
  const showStart = permitted && device.isDown() && device.capabilities.resume;
  const showStop = permitted && device.isOnline();

  if (showStart || showStop) {
    return [
      {
        icon: Icons.play().theme(THEME.PRIMARY).reactComponent(),
        itemDataId: 'context_menu_start_virtual_device',
        label: 'Start project',
        visible: showStart,
        action: onStart,
      },
      {
        icon: Icons.stop().theme(THEME.SUCCESS).reactComponent(),
        itemDataId: 'context_menu_start_virtual_stop',
        label: 'Stop project',
        visible: showStop,
        action: onStop,
      },
      {
        separator: true,
      },
    ];
  }

  return [];
}
