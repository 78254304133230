import React, {useState} from 'react';
import {ButtonProps} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Sx} from 'app/types/common';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {ProjectsSwitcherDialog} from 'app/components/dialogs/ProjectsSwitcherDialog/ProjectsSwitcherDialog';
import {DeviceApiService} from 'app/services/api/device/DeviceApiService';
import {Notifications} from 'app/components/Notifications';

type SwitchAction = React.ComponentProps<typeof ProjectsSwitcherDialog>['action'];

type Props = Sx & {
  action: SwitchAction;
  project: AnyDeviceModelType;
  size?: ButtonProps['size'];
  disabled?: boolean;
};

export function UnifySwitcher({sx, project, action, size, disabled}: Props) {
  const [dialog, setDialog] = useState(false);

  const isStart = action === 'start';

  const handleSwitch = async (action: SwitchAction) => {
    const projectId = project.getId();

    try {
      if (isStart) {
        await DeviceApiService.startProject(projectId);
      } else {
        await DeviceApiService.stopProject(projectId);
      }

      setDialog(false);
    } catch {
      Notifications.addErrorNotification(`Failed to ${action} Unify project`);
    }
  };

  return (
    <>
      <LoadingButton
        sx={sx}
        data-id="unify-switch-btn"
        variant="contained"
        color={isStart ? 'secondary' : 'primary'}
        size={size}
        disabled={disabled}
        onClick={() => setDialog(true)}
      >
        {isStart ? 'Start' : 'Stop'}
      </LoadingButton>

      <ProjectsSwitcherDialog
        open={dialog}
        names={[project.getName()]}
        action={action}
        onSwitch={handleSwitch}
        onClose={() => setDialog(false)}
      />
    </>
  );
}
