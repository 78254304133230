import React, {useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {LoadingButton} from '@mui/lab';
import {Prompt} from 'app/types/common';
import {useMounted} from 'app/hooks/useIsMounted';
import {isNil} from 'app/util/isNil';
import {formatPrice} from 'app/util/prices';

const scheme = z.object({
  name: z.string().trim().min(1, {message: 'Required field'}),
});

type Form = z.infer<typeof scheme>;

type Props = {
  loading: boolean;
  price?: number;
  onCreate: (name: string) => Promise<void>;
  onClose: () => void;
};

function CreateProject({loading, price, onCreate, onClose}: Props) {
  const {control, handleSubmit} = useForm<Form>({
    defaultValues: {name: ''},
    mode: 'onChange',
    resolver: zodResolver(scheme),
  });

  const handleCreate = async ({name}: Form) => {
    await onCreate(name);
  };

  return (
    <Box>
      <DialogTitle>Create a new Unify project</DialogTitle>

      <DialogContent>
        <Typography mb={1}>Epiphan Unify will create and run a new project for you.</Typography>

        <Box component="form" id="create-project" onSubmit={handleSubmit(handleCreate)}>
          <Controller
            control={control}
            name="name"
            render={({field, fieldState: {error}}) => (
              <TextField
                {...field}
                inputProps={{'data-id': 'project-name-input'}}
                autoFocus={true}
                variant="standard"
                label="Name"
                placeholder="Enter your project name"
                fullWidth={true}
                helperText={error?.message ?? ' '}
                error={!isNil(error)}
              />
            )}
          />
        </Box>

        {!isNil(price) && (
          <Typography>
            Every project costs <strong>${formatPrice(price)}</strong> per each running hour (when
            project is active).
            <br />
            You can activate or deactivate projects as needed.
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <LoadingButton
          data-id="confirm-create-btn"
          type="submit"
          variant="contained"
          color="secondary"
          form="create-project"
          loading={loading}
          disableRipple={false}
        >
          Create
        </LoadingButton>

        <Button
          data-id="cancel-create-btn"
          variant="outlined"
          color="info"
          disableRipple={false}
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
}

type PromptProps = Prompt & Omit<Props, 'loading'>;

export function CreateProjectDialog({open, price, onCreate, onClose}: PromptProps) {
  const mounted = useMounted();
  const [loading, setLoading] = useState(false);

  const handleCreate = async (name: string) => {
    try {
      setLoading(true);
      await onCreate(name);
    } finally {
      if (mounted()) {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={onClose}>
      <CreateProject price={price} loading={loading} onCreate={handleCreate} onClose={onClose} />
    </Dialog>
  );
}
