import {CloudContract} from 'app/contracts/cloud';
import {Cloud} from 'app/domain/cloud';
import {Edge} from 'app/domain/edge';

function mapRtmpDestination(c: CloudContract.RtmpDestination): Cloud.RtmpDestination {
  return {
    id: c.StreamID,
    name: c.Name,
    type: c.Type,
    active: c.CurrentlyStreaming,
    ownedBy: c.LockByDevice,
    deleted: c.IsDeleted,
    lastModified: c.LastModified,
    rtmp: {
      key: c.RTMP.StreamingKey,
      name: c.RTMP.DestName,
      url: c.RTMP.URL,
    },
  };
}

function mapGoogleDestination(c: CloudContract.GoogleDestination): Cloud.GoogleDestination {
  const auth = c.OAuth2;

  return {
    id: c.StreamID,
    name: c.Name,
    type: c.Type,
    active: c.CurrentlyStreaming,
    ownedBy: c.LockByDevice,
    deleted: c.IsDeleted,
    lastModified: c.LastModified,
    auth: {
      service: 'google',
      login: auth.Login,
      userName: auth.UserName,
      externalId: auth.ExternalID,
      error: auth.Error,
      errorTime: auth.ErrorTime,
    },
  };
}

function mapDestination(
  c: CloudContract.StreamDestination,
): Cloud.StreamingDestination | undefined {
  if (c.Type === 'rtmp' || c.Type === 'rtmps') {
    return mapRtmpDestination(c);
  }

  if (c.Type === 'google') {
    return mapGoogleDestination(c);
  }

  return undefined;
}

function mapUnitCapabilities(c: CloudContract.UnitCapabilities): Cloud.UnitCapabilities {
  return {
    audioLevels: c.audio_levels?.enabled ?? false,
    batch: c.batch_selection?.enabled ?? false,
    epiphanCms: c.epiphan_cms?.enabled ?? false,
    files: c.file_management?.enabled ?? false,
    hls: c.start_hls_stream?.enabled ?? false,
    groups: c.use_groups?.enabled ?? false,
    login: c.remote_login?.enabled ?? false,
    presets: c.use_preset?.enabled ?? false,
    reboot: c.reboot?.enabled ?? false,
    recording: c.recording?.enabled ?? false,
    schedule: c.use_cms?.enabled ?? false,
    streaming: c.streaming?.enabled ?? false,
    transcribing: c.transcribing?.enabled ?? false,
    resume: c.resume?.enabled ?? false,
  };
}

function mapPairedSummary(c: CloudContract.PairedSummary): Edge.Paired {
  const {count, livescrypt, vpearl} = c;
  return {devices: count, lvs: livescrypt, projects: vpearl};
}

export const CloudMapper = {mapDestination, mapUnitCapabilities, mapPairedSummary};
