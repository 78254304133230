import React, {useEffect, useMemo, useRef} from 'react';
import classNames from 'classnames';
import {Google} from 'app/components/Authorization/Google';
import {AUTH_STATE} from 'app/components/Login/LoginForm/constants';
import {ClassName} from 'app/types/common';

interface Props extends ClassName {
  authState: AUTH_STATE;
  next: string;
  state: string;
}

export function GoogleButton({className, next, state, authState}: Props) {
  const buttonRef = useRef<HTMLDivElement>(null);

  const provider = useMemo(() => {
    return new Google(state, next);
  }, [next, state]);

  useEffect(() => {
    if (buttonRef.current) {
      provider.renderButton(buttonRef.current, authState === AUTH_STATE.SIGN_IN);
    }
  }, [authState, provider]);

  return (
    <div
      ref={buttonRef}
      className={classNames('google-button', className)}
      data-id="google_sign_in"
    />
  );
}
