import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import {LoginForm} from 'app/components/Login/LoginForm';
import {front} from 'app/api/CirrusApi';
import {InvitedModalNotification} from 'app/components/libs/dialogs/InvitedModalNotification';
import {LoggingErrorModalNotification} from 'app/components/libs/dialogs/LoggingErrorModalNotification';
import {getAuthStateByRouteName} from 'app/components/Login/utils';
import {Footer} from 'app/components/Footer';
import {Callback} from 'app/types/common';
import {AuthResponseDto} from 'app/components/Authorization/types';
import {processAuthResponse} from 'app/components/Authorization/utils';

interface Props {
  invited: boolean;
  error: boolean;
  next: string;
  state: string;
  routeName: string;
  onClickLogo: Callback;
  onChangeAuthState: Callback;
}

export const Login = ({
  invited,
  error,
  next,
  state,
  routeName,
  onClickLogo,
  onChangeAuthState,
}: Props) => {
  useEffect(() => {
    if (error) {
      LoggingErrorModalNotification.show();
    } else {
      InvitedModalNotification.show(invited);
    }
  }, []);

  return (
    <div className="login-page" id="login">
      <div className="login-page__logo">
        <Box
          component="img"
          src="/assets/img/logo/logo-bigger.png"
          alt="Epiphan Cloud"
          data-id="logo"
          onClick={onClickLogo}
        />
      </div>

      <div className="login-page__content login-content">
        <LoginForm
          next={next}
          state={state}
          authState={getAuthStateByRouteName(routeName)}
          onSignedIn={async (args) => {
            const {state: argsState, ...restArgs} = args;

            const res: AuthResponseDto = await front
              .oauth()
              .internal_cognito({next, state: args.state ?? state})
              .postForm(restArgs);

            processAuthResponse(res);
          }}
          onChangeState={onChangeAuthState}
        />
      </div>

      <Footer loggedIn={false} />
    </div>
  );
};
