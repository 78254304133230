import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {ColumnsSelector} from 'app/components/FleetManager/ColumnsHeader/ColumnsSelector';
import {Tooltip} from 'app/components/sharedReactComponents/Tooltip';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import type {DragEndEvent} from '@dnd-kit/core';
import {restrictToParentElement} from '@dnd-kit/modifiers';
import {Callback, ClassName} from 'app/types/common';
import {FleetManagerColumnId} from 'app/components/FleetManager/constants';
import {FleetManagerColumn} from 'app/components/FleetManager/types';

interface RearrangeColumnListProps extends ClassName {
  columns: FleetManagerColumn[];
  fixedColumnIds: FleetManagerColumnId[];
  onChange: (columnId: string, nextIndex: number) => void;
}

const RearrangeColumnList: React.FC<RearrangeColumnListProps> = ({
  className,
  columns,
  fixedColumnIds,
  onChange,
}) => {
  const [sortedColumns, setSortedColumns] = useState<FleetManagerColumn[]>([]);
  const ids = sortedColumns.map(({dataKey}) => dataKey);

  useEffect(() => {
    setSortedColumns(columns);
  }, [columns, fixedColumnIds]);

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;

    // dropped outside the list
    if (!over) {
      return;
    }

    if (active.id !== over.id) {
      onChange(active.id as FleetManagerColumnId, ids.indexOf(over.id as FleetManagerColumnId));
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <DndContext
      autoScroll={false}
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToParentElement]}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={ids} strategy={horizontalListSortingStrategy}>
        <div className={classNames(className, 'rearrange-column-list')}>
          {sortedColumns.map((column, index) => (
            <Item
              key={column.dataKey}
              index={index}
              column={column}
              draggable={!fixedColumnIds.includes(column.dataKey)}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

interface ItemProps {
  column: FleetManagerColumn;
  index: number;
  draggable: boolean;
}

function Item({column, index, draggable}: ItemProps) {
  if (draggable) {
    return <DraggableItem index={index} column={column} />;
  }

  return <ItemComponent column={column} />;
}

interface DraggableItemProps {
  index: number;
  column: FleetManagerColumn;
}

function DraggableItem({column}: DraggableItemProps) {
  const {attributes, isDragging, listeners, setNodeRef, transform} = useSortable({
    id: column.dataKey,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    width: column.width,
  };

  return (
    <div
      ref={setNodeRef}
      className={classNames(
        'rearrange-column-list__item',
        isDragging && 'rearrange-column-list__item--dragging',
      )}
      style={style}
      {...attributes}
      {...listeners}
    >
      {isDragging ? <DraggingItem label={column.label} /> : <ItemComponent column={column} />}
    </div>
  );
}

function DraggingItem({label}: {label: React.ReactNode}) {
  return <div className="rearrange-column-list__item-clone">{label}</div>;
}

function ItemComponent({column}: {column: FleetManagerColumn}) {
  const {width, label, detailedLabel} = column;

  return (
    <div className="fleet-manager-columns-header__item" style={{width}}>
      <Tooltip content={detailedLabel} delay={250}>
        {label}
      </Tooltip>
    </div>
  );
}

interface Props {
  columns: FleetManagerColumn[];
  activeColumns: FleetManagerColumn[];
  fixedColumnIds: FleetManagerColumnId[];
  isColumnActive: Callback;
  withGroups: boolean;
  onChange: Callback;
  onChangeArrangement: (columnId: string, nextIndex: number) => void;
}

export const ColumnsHeader = observer<React.VFC<Props>>(
  ({
    columns,
    activeColumns,
    fixedColumnIds,
    isColumnActive,
    withGroups,
    onChange,
    onChangeArrangement,
  }) => {
    const fixedColumns: FleetManagerColumn[] = [];
    const arrangedColumns: FleetManagerColumn[] = [];

    for (const activeColumn of activeColumns) {
      if (fixedColumnIds.includes(activeColumn.dataKey)) {
        fixedColumns.push(activeColumn);
      } else {
        arrangedColumns.push(activeColumn);
      }
    }

    if (fixedColumns[0]) {
      arrangedColumns.unshift(fixedColumns[0]);
    }

    return (
      <div
        className={classNames('fleet-manager-columns-header', {
          'fleet-manager-columns-header--with-groups': withGroups,
        })}
      >
        <div className="fleet-manager-columns-header__container">
          <div className="fleet-manager-columns-header__content">
            <div className="fleet-manager-columns-header__item fleet-manager-columns-header__item--name">
              Name
            </div>

            <RearrangeColumnList
              className="fleet-manager-columns-header__rearrange-column-list"
              columns={arrangedColumns}
              fixedColumnIds={fixedColumnIds}
              onChange={onChangeArrangement}
            />

            {fixedColumns[1] && <ItemComponent column={fixedColumns[1]} />}

            <div className="fleet-manager-columns-header__item fleet-manager-columns-header__item--actions">
              <ColumnsSelector
                columns={columns}
                fixedColumnIds={fixedColumnIds}
                isColumnActive={isColumnActive}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

ColumnsHeader.displayName = 'ColumnsHeader';
