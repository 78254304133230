import {BaseBilling} from 'app/components/entities/billing/base/model';
import {pluralizeN} from 'app/components/helpers/commonHelpers';

export function formatBillingPeriod({count, interval}: BaseBilling.Config): string {
  return count === 1 ? `per ${interval}` : `every ${count}  ${pluralizeN(interval, count)}`;
}

export function formatPrice(amount: number, fraction = 0): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  }).format(amount);
}

export function formatHours(hours: number, fraction = 0): string {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: fraction,
  }).format(hours);
}

export function formatTrialSeconds(seconds: number): string {
  if (seconds < 60) {
    return 'less than a minute';
  }

  const minutes = Math.trunc(seconds / 60);

  if (minutes < 60) {
    return `${minutes} ${pluralizeN('minute', minutes)} `;
  }

  const hours = Math.round(minutes / 60);
  return `${hours} ${pluralizeN('hour', hours)}`;
}
