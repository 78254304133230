import React, {useState} from 'react';
import {Box, Button, Paper, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {WistiaPlayer} from '@wistia/wistia-player-react';
import {Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {router} from 'app/router/main';
import {isNil} from 'app/util/isNil';
import {formatTrialSeconds} from 'app/components/entities/billing/base';

interface Props extends Sx {
  trialSeconds?: number;
  billingAccess?: boolean;
}

export function ConnectGuide({sx, trialSeconds, billingAccess}: Props) {
  const [expanded, setExpanded] = useState(true);

  const isTrial = !isNil(trialSeconds);

  const renderMessage = () => {
    if (!isTrial) {
      return null;
    }

    if (!trialSeconds) {
      return (
        <Typography color="error.main" mt={2}>
          Your free trial hours of Connect have been used.
        </Typography>
      );
    }

    return (
      <Typography color="text.secondary" mt={2}>
        Your free trial has <b>{formatTrialSeconds(trialSeconds)} of Connect</b> remaining.
      </Typography>
    );
  };

  return (
    <Paper sx={packSx(sx, {bgcolor: grey[100]})} variant="outlined">
      <Stack direction="row" justifyContent="space-between">
        <Stack p={2} alignItems="flex-start">
          <Typography variant="h5" fontWeight={600} mb={2}>
            Getting started
          </Typography>

          <Typography color="text.secondary" mb={2}>
            Learn the basics of projects, adding sources, channels, layouts,
            <br /> switching, streaming, and recording.
          </Typography>

          {expanded && isTrial && (
            <>
              {renderMessage()}

              {billingAccess && (
                <Button
                  sx={{my: 2}}
                  variant="contained"
                  onClick={() => router.go('settingBillingUsage')}
                >
                  Upgrade subscription
                </Button>
              )}
            </>
          )}

          <Button
            sx={{mt: 'auto'}}
            startIcon={<ExpandMoreIcon sx={packSx(expanded && {transform: `rotate(180deg)`})} />}
            onClick={() => setExpanded((prev) => !prev)}
          >
            {expanded ? 'Collapse' : 'Expand'}
          </Button>
        </Stack>

        {expanded && (
          <Box sx={{aspectRatio: '16/9', width: 533}}>
            <WistiaPlayer mediaId="nbqts6e74a" />
          </Box>
        )}
      </Stack>
    </Paper>
  );
}
