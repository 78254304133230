/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {useCallback} from 'react';
import classNames from 'classnames';
import {Stack} from '@mui/material';
import {SelectCheckbox} from 'app/components/FleetManager/DeviceCard/SelectCheckbox';
import {StatusList} from 'app/components/FleetManager/DeviceCard/StatusList';
import {ContextMenu} from 'app/components/FleetManager/DeviceCard/ContextMenu';
import {DetailsToggler} from 'app/components/sharedReactComponents/DetailsToggler';
import {OverflowTooltip} from 'app/components/sharedReactComponents/OverflowTooltip/OverflowTooltip';
import {noop} from 'app/util/noop';
import {router} from 'app/router/main';
import {Tooltip, TOOLTIP_PLACEMENT} from 'app/components/sharedReactComponents/Tooltip';
import {UpgradeToProTooltip} from 'app/components/FleetManager/UpgradeToProTooltip';
import {THEME} from 'app/constants';
import {useToggle} from 'app/hooks/useToggle';
import {Callback, ClassName, DataId, TimeStampSeconds} from 'app/types/common';
import {EventInfo} from 'app/components/sharedReactComponents/Events/shared/EventInfo/EventInfo';
import {AnyDeviceModelType} from 'app/components/DeviceDetails/Models/Fabric';
import {PearlMasterDeviceModel} from 'app/components/DeviceDetails/Models/PearlMasterDeviceModel';
import {DeviceDetails} from 'app/components/FleetManager/DeviceCard/DeviceDetails/DeviceDetails';
import {FleetManagerColumn} from 'app/components/FleetManager/types';
import {AnyStreamingDestinationModelType} from 'app/components/StreamingServices/types';
import {ModelService} from 'app/services/deviceModel/DeviceModelService';
import {useDeviceStatus} from 'app/components/FleetManager/DeviceCard/useStatusProgress';
import {isOngoing, isScheduled} from 'app/domain/schedule/utils';
import {Schedule} from 'app/domain/schedule';
import {isNil} from 'app/util/isNil';
import {UnifyConnection} from 'app/components/sharedReactComponents/UnifyConnection/UnifyConnection';
import {UserRole} from 'app/models/PermissionsModel/types';
import {Edge} from 'app/domain/edge';

interface DeviceNameProps extends DataId {
  device: AnyDeviceModelType;
}

const DeviceName: React.FC<DeviceNameProps> = React.memo(({device, dataId}) => {
  const detailsUrl = getDetailsUrl(device);

  return (
    <a className="device-card__name-link" href={detailsUrl}>
      <OverflowTooltip dataId={dataId}>{device.getName()}</OverflowTooltip>
    </a>
  );
});

interface Props extends ClassName, DataId {
  device: AnyDeviceModelType;
  role: UserRole;
  groups: Edge.Group[];
  presets: Edge.TeamPreset[];
  event?: Schedule.Event;

  columns: FleetManagerColumn[];
  streamingDestinations?: AnyStreamingDestinationModelType[];
  selectable?: boolean;
  opened?: boolean;

  getDeviceById: (id: string) => AnyDeviceModelType | undefined;
  onApplyPreset: (preset: Edge.TeamPreset) => Promise<void>;
  onMoveToGroup: (groupId: string) => Promise<void>;
  onRemoveFromGroup: (device: AnyDeviceModelType) => Promise<void>;
  onStartProject?: () => Promise<void>;
  onStopProject?: () => Promise<void>;
  onDelete: () => Promise<void>;

  checkIsDeviceSelected?: Callback;
  toggleDeviceSelection?: Callback;
  unpairAction?: Callback;
  onChangeStreamingDestination?: Callback;
}

export function DeviceCard({
  dataId,
  role,
  device,
  groups,
  className,
  event,
  presets,

  columns,
  streamingDestinations = [],
  selectable = true,
  opened = true,

  getDeviceById,
  onApplyPreset,
  onMoveToGroup,
  onRemoveFromGroup,
  onStartProject,
  onStopProject,
  onDelete,

  checkIsDeviceSelected = () => false,
  toggleDeviceSelection = noop,
  unpairAction = noop,
  onChangeStreamingDestination = noop,
}: Props) {
  const [detailsOpened, toggleDetailsOpened] = useToggle(opened);

  const deviceId = device.getId();

  const handleChangeSelect = useCallback(() => {
    toggleDeviceSelection(device);
  }, [device, toggleDeviceSelection]);

  const selected = selectable && checkIsDeviceSelected(deviceId);

  // Stylize as Selected if some Channel-device selected
  let indeterminateSelected = false;
  let allChildDevicesSelected = false;

  if (ModelService.isMultiChannel(device.getModelName())) {
    const channels = (device as PearlMasterDeviceModel).getChanelModels();

    if (channels.length > 0) {
      const selectedDevices = channels.filter((device) => checkIsDeviceSelected(device.getId()));

      allChildDevicesSelected = selectedDevices.length === channels.length;
      indeterminateSelected = selectedDevices.length > 0 && allChildDevicesSelected === false;
    }
  }

  const detailsAvailable = isDetailsAvailable(device);

  const {batch} = device.capabilities;
  const isDeviceSelectable = selectable && batch;

  const status = useDeviceStatus(device);

  const hasOngoingEvent = !isNil(event) && isOngoing(event.status);

  const handleRemoveFromGroup = async () => {
    if (!device.getGroupId()) {
      return;
    }

    await onRemoveFromGroup(device);
  };

  return (
    <div
      data-id={dataId}
      className={classNames(
        'device-card',
        {
          'device-card--offline': device.isOffline(),
          'device-card--selectable': selectable,
          'device-card--selected': selected || indeterminateSelected || allChildDevicesSelected,
        },
        className,
      )}
      tabIndex={selectable ? 0 : undefined}
    >
      {status.showProgress && (
        <UnifyConnection dataId="unify_status_progress" value={status.progress} />
      )}

      <div className="device-card__container">
        <div className="device-card__content">
          <div className="device-card__select">
            <Tooltip
              content={
                !batch && device.isOnline() && !device.isUnpaired() ? (
                  <UpgradeToProTooltip canEdit={role.canEditBilling()} />
                ) : (
                  ''
                )
              }
              interactive={true}
              trigger="click"
              placement={TOOLTIP_PLACEMENT.BOTTOM}
              theme={THEME.WHITE}
            >
              <SelectCheckbox
                data-id="device_card_checkbox"
                selected={selected}
                indeterminate={indeterminateSelected}
                disabled={!isDeviceSelectable}
                onChange={handleChangeSelect}
              />
            </Tooltip>
          </div>

          <div className="device-card__name">
            <DeviceName dataId="device_card_name" device={device} />
          </div>

          <StatusList columns={columns} device={device} role={role} progress={status?.progress} />

          <Stack direction="row" alignItems="center" minWidth={70}>
            <ContextMenu
              className="device-card__context-menu"
              device={device}
              groups={groups}
              presets={presets}
              permitPreset={role.canApplyPresets()}
              permitReboot={role.canRebootDevices()}
              permitUnify={role.canManageUnify()}
              onApplyPreset={onApplyPreset}
              onMoveToGroup={onMoveToGroup}
              onStartProject={onStartProject}
              onStopProject={onStopProject}
              onRemoveFromGroup={handleRemoveFromGroup}
              unpairAction={unpairAction}
              onDelete={onDelete}
            />

            <div className="device-card__toggler" data-id="device_card_toggler">
              {detailsAvailable && (
                <DetailsToggler
                  className="device-card__details-toggler"
                  opened={detailsOpened}
                  onClick={toggleDetailsOpened}
                />
              )}
            </div>
          </Stack>
        </div>

        {event && (
          <EventInfo
            sx={{ml: 3.5, maxWidth: 500}}
            event={event}
            showTime={true}
            deviceId={device.getId()}
            label={isScheduled(event.status) ? 'Starts in' : undefined}
          />
        )}

        {detailsOpened && detailsAvailable && (
          <div className="device-card__details">
            <DeviceDetails
              device={device}
              hasEvent={hasOngoingEvent}
              streamingDestinations={streamingDestinations}
              selectable={selectable}
              getDeviceById={getDeviceById}
              role={role}
              checkIsDeviceSelected={checkIsDeviceSelected}
              toggleDeviceSelection={toggleDeviceSelection}
              onChangeStreamingDestination={onChangeStreamingDestination}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function getDetailsUrl(device: AnyDeviceModelType): string {
  const deviceId = device.getId();

  if (ModelService.isLivescrypt(device.getModelName())) {
    return router.url('transcribeDeviceDetails', {deviceId});
  }

  if (ModelService.isUnify(device.getModelName())) {
    return router.url('projectDetails', {deviceId});
  }

  return router.url('deviceDetails', {deviceId});
}

export function getUnifyUpdateTimestamp(device: AnyDeviceModelType): TimeStampSeconds | undefined {
  if (device instanceof PearlMasterDeviceModel) {
    const status = device.getStatus();

    if (status === 'starting') {
      return device.createdAt;
    }

    if (status === 'resuming') {
      return device.updatedAt;
    }
  }
}

function isDetailsAvailable(device: AnyDeviceModelType): boolean {
  const online = device.isOnline();

  if (ModelService.isMultiChannel(device.getModelName())) {
    return (device as PearlMasterDeviceModel).hasChannels() && online;
  }

  return online;
}
