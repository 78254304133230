import React from 'react';
import {Box, Card, Divider, Stack, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {Component, StyleSx, Sx} from 'app/types/common';
import {packSx} from 'app/util/packSx/packSx';
import {formatAbbreviatedDateWithYear} from 'app/util/time';
import {palette} from 'app/themes/app';
import {formatPrice} from 'app/components/shared';
import {Billing} from 'app/domain/billing';

const rootSx: StyleSx = {p: 5, bgcolor: grey[50]};

export interface Row {
  name: string;
  total: number;
  description: React.ReactNode;
}

interface Props extends Sx, Component {
  issueDate: TimeStampSeconds;
  period: Billing.TimePeriod;
  rows: Row[];
  total: number;
}

export function InvoiceCard({sx, dataId, issueDate, period, rows, total}: Props) {
  return (
    <Card data-id={dataId} sx={packSx(rootSx, sx)} variant="outlined">
      <Box mb={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography variant="h5" fontWeight={600}>
            Upcoming invoice
          </Typography>

          <Typography data-id="invoice-period" color={palette.darkerGray}>
            {formatAbbreviatedDateWithYear(period.begin)} -{' '}
            {formatAbbreviatedDateWithYear(period.end)}
          </Typography>
        </Stack>
        <Typography data-id="invoice-date" color={palette.blue}>
          Will be issued on: {formatAbbreviatedDateWithYear(issueDate)}
        </Typography>
      </Box>

      <Stack gap={2} mb={2} divider={<Divider flexItem={true} />}>
        {rows.map((r, i) => {
          return (
            <Box key={r.name} data-id={`invoice-row-${i}`}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.5}>
                <Typography data-id="invoice-row-name" fontSize={16}>
                  {r.name}
                </Typography>
                <Typography data-id="invoice-row-total" fontSize={16} fontWeight={600}>
                  ${formatPrice(r.total)}
                </Typography>
              </Stack>

              <Typography data-id="invoice-row-description">{r.description}</Typography>
            </Box>
          );
        })}
      </Stack>

      <Typography
        data-id="invoice-total"
        fontSize={16}
        fontWeight={600}
        color={palette.blue}
        textAlign="right"
      >
        Total period amount: ${formatPrice(total)}
      </Typography>
    </Card>
  );
}
